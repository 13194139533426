<template>
  <div id="specaialpartice">
    <div class="top-title">
      <div>专项练习</div>
    </div>
    <div class="partice-list">
      <el-table
        :data="tableData"
        style="width: 100%"
        :show-header="false"
        @row-click="choicetype"
      >
        <el-table-column prop="name" width="1100"> </el-table-column>
        <el-table-column>
          <i class="el-icon-arrow-right"></i>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: "专项练习 - 西知题库",
  },
  data() {
    return {
      tableData: [],
    };
  },
  created() {
     this.loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    let obj = {
      page: 1,
      limit: 99999,
    };
    this.$api.classes(obj).then((res) => {
      this.tableData = res.data.lists.data;
      this.loading.close();
    });
  },
  methods: {
    choicetype(row) {
      console.log();
       this.$router.push({
        path: "/Index/PersonalCenter/QuestionType",
        query: {
          id: row.id,
          name:row.name
        },
      });
       
    },
  },
};
</script>

<style lang="less" scoped>
#specaialpartice {
  padding-top: 50px;
  width: 1180px;
  margin: auto;
  min-height: 835px;
  .top-title {
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  .partice-list {
    /deep/.el-table {
      border-radius: 4px;
      .cell {
        padding-left: 20px;
      }
    }
    /deep/.el-table td {
      padding: 20px 0;
    }
  }
}
</style>